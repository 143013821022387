import React from 'react';
import Layout from '../components/Layout';

const About = (props) => {
    return (
        <Layout>
            <h2>
                About us
            </h2>
            <p>We're a small group of people that want to help others visualize their financials.</p>
            <p>Too much of our financials get blurred away in our day to day. We want to change that. We want people to feel confident in their choices. We want people to go to their financial advisors with more clarity.</p>
        </Layout>
    );
}

export default About;